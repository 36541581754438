<template>
  <master-layout>

    <div class="centerPlateformeErreur">
      <ion-card>
        <ion-card-content>
          <h1>Üdvözlünk az<br/>Országos Bortúra<br/>alkalmazásában!</h1>
          <p>
            Egyelőre az applikáció csak <b>IOS és Android</b> telefonokon használható.
          </p>
          <img src="assets/images/welcome-illustration.svg" class="illustration"/>
        </ion-card-content>
      </ion-card>
      <ion-button expand="full" shape="round" href="https://www.orszagosbortura.hu">www.orszagosbortura.hu</ion-button>
    </div>

  </master-layout>
</template>

<script>
import {
  IonCard, IonCardContent, IonButton
} from '@ionic/vue'

export default {
  components: {
    IonCard, IonCardContent, IonButton
  }
}
</script>

<style scoped>
.centerPlateformeErreur{
  max-width: 500px;
  margin: 0 auto 0 auto;
  display: block;
}
ion-button{
  margin: 20px 40px;
}
h1{
  margin-top: 40px;
  margin-bottom: 20px;
  line-height: 33px;
}
p{
  text-align: center;
}
.illustration{
  display: block;
  width: 90%;
  margin: 40px auto 20px auto;
}
</style>
